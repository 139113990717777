import React from 'react'
import { Cloudinary } from '@cloudinary/base'

const cloudinary = new Cloudinary({
  cloud: {
    cloudName: process.env.GATSBY_CLOUDINARY_NAME,
  },
})

export const CloudinaryContext = React.createContext<Cloudinary>(cloudinary)

export const CloudinaryProvider = ({ children }) => (
  <CloudinaryContext.Provider value={cloudinary}>
    {children}
  </CloudinaryContext.Provider>
)
